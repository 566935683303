<template>
    <section class="section-default">
        <div class="col-left">
            <div class="col-left-container">
                <img src="./../assets/icon-contact.png" alt="contact" class="contact-icon" />
                <h2>NOUS CONTACTER</h2>
                <p>MARJONAT DISTRIBUTION met tout en œuvre pour répondre à vos questions et satisfaire vos demandes.</p>
                <div class="contact-sep"></div>
                <img src="./../assets/icon-map.png" alt="map" class="contact-icon" />
                <h2>OÙ NOUS TROUVER ?</h2>
                <p>4, Rue Mahatma Gandhi<br />
                Espace Beauvalle – Bât. B<br />
                13 090 Aix-en-Provence<br />
                France</p>
            </div>
        </div>
        <div class="col-main col-main-first">
            <contact-form></contact-form>
        </div>
    </section>
</template>

<script>
import ContactForm from "./../components/ContactForm.vue";

export default {
    name : "Contact",
    components : {
        ContactForm
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    .col-left {
        background:$secondColor;
    }

    .contact-icon {
        width:50px;
        margin:20px auto;
        display: block;
    }

    .contact-sep {
        width:100%;
        height:1px;
        margin-top:20px;
        margin-bottom:20px;
    }

    .col-main {
        padding-bottom: 80px;
    }

    @media screen and (max-width:768px) {
        .section-default .col-left {
            padding-top:140px;
        }
    }
</style>