<template>
    <template v-if="loading">
        <loader></loader>
    </template>
    <template v-else>
        <div class="contact-form">
            <form @submit.prevent="onSubmit" v-if="!success">
            <!-- ROW -->
            <div class="row">
                <div class="col-50">
                    <!-- Lastname -->
                    <div class="form-group" :class="{ error: v$.form.lastname.$errors.length }">
                        <input class="form-control" placeholder="Nom *" type="text" v-model="v$.form.lastname.$model">
                        <!-- error message -->
                        <template v-if="v$.form.lastname.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.lastname.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            <div class="error-msg" v-if="v$.form.lastname.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.lastname.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
                <div class="col-50">
                    <!-- Firstname -->
                    <div class="form-group" :class="{ error: v$.form.firstname.$errors.length }">
                        <input class="form-control" placeholder="Prénom *" type="text" v-model="v$.form.firstname.$model">
                        <!-- error message -->
                        <template v-if="v$.form.firstname.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.firstname.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
            </div>
            <!-- ROW -->
            <div class="row">
                <div class="col-100">
                    <!-- Society -->
                    <div class="form-group" :class="{ error: v$.form.society.$errors.length }">
                        <input class="form-control" placeholder="Société" type="text" v-model="v$.form.society.$model">
                        <!-- error message -->
                        <template v-if="v$.form.society.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.society.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.society.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
            </div>

            <!-- ROW -->
            <div class="row">
                <div class="col-100">
                    <!-- address -->
                    <div class="form-group" :class="{ error: v$.form.address.$errors.length }">
                        <input class="form-control" placeholder="Adresse" type="text" v-model="v$.form.address.$model">
                        <!-- error message -->
                        <template v-if="v$.form.address.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.address.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.address.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
            </div>
                        <!-- ROW -->
            <div class="row">
                <div class="col-100">
                    <!-- address -->
                    <div class="form-group" :class="{ error: v$.form.address2.$errors.length }">
                        <input class="form-control" placeholder="Complément d'adresse" type="text" v-model="v$.form.address2.$model">
                        <!-- error message -->
                        <template v-if="v$.form.address2.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.address2.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.address2.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
            </div>
            <!-- ROW -->
            <div class="row">
                <div class="col-20">
                    <!-- zip -->
                    <div class="form-group" :class="{ error: v$.form.zipcode.$errors.length }">
                        <input class="form-control" placeholder="Code postal" type="text" v-model="v$.form.zipcode.$model">
                        <!-- error message -->
                        <template v-if="v$.form.zipcode.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.zipcode.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.zipcode.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
                <div class="col-40">
                    <!-- zip -->
                    <div class="form-group" :class="{ error: v$.form.city.$errors.length }">
                        <input class="form-control" placeholder="Ville" type="text" v-model="v$.form.city.$model">
                        <!-- error message -->
                        <template v-if="v$.form.city.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.city.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.city.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
                <div class="col-40">
                    <!-- zip -->
                    <div class="form-group" :class="{ error: v$.form.country.$errors.length }">
                        <input class="form-control" placeholder="Pays" type="text" v-model="v$.form.country.$model">
                        <!-- error message -->
                        <template v-if="v$.form.country.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.country.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.country.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
            </div>

            <!-- ROW -->
            <div class="row">
                <div class="col-50">
                    <!-- Email -->
                    <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
                        <input class="form-control" placeholder="Email *" type="text" v-model="v$.form.email.$model">
                        <!-- error message -->
                        <template v-if="v$.form.email.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'email'">Veuillez saisir une adresse email valide</div>
                            <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.lastname.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
                <div class="col-50">
                    <!--Téléphone -->
                    <div class="form-group" :class="{ error: v$.form.phone.$errors.length }">
                        <input class="form-control" placeholder="Téléphone *" type="text" v-model="v$.form.phone.$model">
                        <!-- error message -->
                        <template v-if="v$.form.phone.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.phone.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            <div class="error-msg" v-if="v$.form.phone.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.phone.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                </div>
            </div>
            <!-- ROW -->
            <div class="row">
                <div class="col-100">
                    <!--Message -->
                        <div class="form-group" :class="{ error: v$.form.message.$errors.length }">
                            <textarea class="form-control" placeholder="Message *" v-model="v$.form.message.$model" rows="5"></textarea>
                            <!-- error message -->
                            <template v-if="v$.form.message.$errors.length > 0">
                                <div class="input-errors" v-for="(error, index) of v$.form.message.$errors" :key="index">
                                    <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">Ce champs est requis</div>
                                     <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.message.$errors[0].$params.max}} caractères</div>
                                    <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'min'">Ce champs doit comporter au moins {{v$.form.message.$errors[0].$params.min}} caractères</div>

                                </div>
                            </template>
                        </div> 
                </div>  
            </div>
            <div class="row">
                <div class="col-100">
                    <div class="form-group" :class="{ error: v$.form.rgpd.$errors.length }">
                        <div class="checkbox-container">
                            <input class="form-control" type="checkbox" v-model="v$.form.rgpd.$model" />
                            <label>En soumettant ce formulaire j’accepte que les informations saisies soient exploitées par MARJONAT DISTRIBUTION dans le cadre de ma demande et de la relation commerciale qui peut en découler.</label>
                        </div>
                        <!-- error message -->
                        <template v-if="v$.form.rgpd.$errors.length > 0">
                            <div class="input-errors" v-for="(error, index) of v$.form.rgpd.$errors" :key="index">
                                <div class="error-msg" v-if="v$.form.rgpd.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            </div>
                        </template>
                    </div> 
                </div>
            </div>
            <!-- Submit Button -->
            <div class="row-btn">
                <button :disabled="v$.form.$invalid" class="btn">Envoyer</button>
            </div>
        </form>
        <!-- Success -->
        <div class="notices success" v-if="success">Votre message a été envoyé avec succès. Nous vous recontacterons très prochainement !</div>
        <!-- Errors -->
        <div class="notices errors" v-if="errors">{{ errors}}</div>
        </div>
    </template>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, maxLength, minLength } from '@vuelidate/validators';
import Loader from "./Loader.vue";
import axios from 'axios';

export default {
    name :"ContactForm",
    components : { Loader },
    setup () {
        return { v$: useVuelidate() }
    },
    data () {
        return {
            form : {
                firstname : null,
                lastname : null,
                society : null,
                address : null,
                address2 : null,
                zipcode : null,
                city : null,
                country : null,
                email : null,
                phone : null,
                message : null,
                rgpd : false
            },
            loading : false,
            errors : null,
            success : false
        }
    },
    validations () {
        return {
            form : {
                firstname : {
                    required, max : maxLength(50)
                },
                lastname : {
                    required, max : maxLength(50)
                },
                society : {
                    max : maxLength(250)
                },
                address : {
                    max : maxLength(250)
                },
                address2 : {
                    max : maxLength(250)
                },
                zipcode : {
                    max : maxLength(25)
                },
                city : {
                    max : maxLength(50)
                },
                country : {
                    max : maxLength(50)
                },
                email : {
                    required, email, max : maxLength(150)
                },
                phone : {
                    required, max : maxLength(50)
                },
                message : {
                    required, max : maxLength(2500), min : minLength(5)
                },
                rgpd : {
                    checked: (val) => {return val;}
                }
            }
        }
    },
    methods : {
        onSubmit() {
            this.loading = true;
            this.errors = null;
            this.success = false;

            let formData = new FormData();

            formData.append('firstname', this.form.firstname);
            formData.append('lastname', this.form.lastname);
            formData.append('email', this.form.email);
            formData.append('message', this.form.message);
            formData.append('rgpd', this.form.rgpd);
            formData.append('phone', this.form.phone);
            if(this.form.society) {
                formData.append('society', this.form.society);
            }    
            if(this.form.address) {
                formData.append('adress', this.form.address);
            }    
            if(this.form.address2) {
                formData.append('adress2', this.form.address2);
            }        
            if(this.form.zipcode) {
                formData.append('zipcode', this.form.zipcode);
            }    
            if(this.form.city) {
                formData.append('city', this.form.city);
            }  
            if(this.form.country) {
                formData.append('country', this.form.country);
            }  

            let config = {
                validateStatus: () => true,
            };
            let vm = this

            axios
                .post('https://marjonatdistribution.fr/mailer/sendmail.php', formData, config)
                .then(function (res) {
                    vm.loading = false;
                    if (res.status == 400) {
                        vm.errors = 'Merci de remplir tous les champs obligatoires.'
                    }else if(res.status == 500) {
                        vm.errors = 'Une erreur est survenue, veuillez réessayer ultérieurement.'
                    
                    }else if(res.status == 200) {
                            vm.success = true;
                            vm.firstname = null,
                            vm.lastname = null,
                            vm.society = null,
                            vm.address = null,
                            vm.address2 = null,
                            vm.zipcode = null,
                            vm.city = null,
                            vm.country = null,
                            vm.email = null,
                            vm.phone = null,
                            vm.message = null,
                            vm.rgpd = false  
                        }else {
                            vm.errors = 'Une erreur est survenue, veuillez réessayer ultérieurement.'
                        }
                    });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    .contact-form {
        display: flex;
        flex-direction: column;
        width:100%;
    }

  form {
        width:1400px;
        max-width:96%;
        margin:0 auto;
    }

    .row {
        display:flex;
        width:100%;
    }

    .col-50 {
        width:46%;
        margin:10px auto;
    }

    .col-20 {
      width:16%;
      margin:10px auto;
    }

    .col-40 {
      width:36%;
      margin:10px auto;
    }

    .col-100 {
        width:96%;
        margin:10px auto; 
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }


    input, textarea{
        appearance: none;
        padding:15px;
        border:1px solid #818a91;
        border-radius:0;
        width:calc(100% - 30px);
        transition: 0.5s;
        font-family: $font;
        color:$mainColor;
        font-weight:600;
        border-radius:5px;
    }

    input:focus, textarea:focus {
        outline:none;
        border:1px solid $mainColor;
    }

    input[type="checkbox"] {
        width:20px;
        height:20px;
        min-width:20px;
        padding:0;
        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        background:$mainColor;
        border:1px solid $mainColor;
    }

    label {
        color:$secondColor;
        margin-left:10px;
        margin-top:-5px;
    }

    .checkbox-container {
        display: flex;
    }

    .form-group.error input, .form-group.error input:focus, .form-group.error textarea, .form-group.error textarea:focus {
        border-color:red;
    }


    .error-msg {
        color:red;
        font-size:12px;
        line-height:1.5em;
    }

    .row-btn {
        display: flex;
        width:100%;
        justify-content: center;
    }

    .row-btn .btn {
        font-weight:700;
    }

    .btn:disabled {
        opacity: 0.5;
        cursor:not-allowed;
    }

        .notices {
        padding:10px;
        width:calc(90% - 20px);
        border-radius:10px;
        text-align:center;
        color:green;
        border:1px solid green;
        margin:20px auto;
        background: #fff;
    }

    .notices.errors {
        color:red;
        border-color:red;
    }

    @media screen and (max-width:768px) { 
        .col-50, .col-20, .col-40, .col-100 {
            width:98%;
        }

        .row {
            flex-direction: column;
        }
    }
</style>